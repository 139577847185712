import CodeExample from "components/docs/code-examples/CodeExample";
import RequestBodyCodeExample from "components/docs/code-examples/RequestBodyCodeExample";
import RequestSchemaCodeExample from "components/docs/code-examples/RequestSchemaCodeExample";
import HeaderBar from "components/docs/layout/HeaderBar";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import LINKS from "components/docs/navigation/links";
import NavigationScrollTracker from "components/docs/navigation/NavigationScrollTracker";
import NavigationScrollTrackerWithAnchor from "components/docs/navigation/NavigationScrollTrackerWithAnchor";
import SmallWidthSection, { Content } from "components/docs/sections/SmallWidthSection";
import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import { InfoCard } from "components/docs/shared-components/NoticeCards";
import SectionDivider from "components/docs/shared-components/SectionDivider";
import { StaticFancyPageContainerWithTOC } from "components/docs/shared-components/StaticFancyPageContainer";
import { Link, PageProps } from "gatsby";
import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { spectrum } from "styles/theme";

const CODE_EXAMPLE_FOLDER = "merge-writes/programmatic/intro";

/**
 * Small width section with padding
 */
const Section = styled(SmallWidthSection).attrs({ className: "mt-9 mb-9" })``;

/**
 * Small width section with smaller padding above
 */
const ShortSection = styled(SmallWidthSection).attrs({ className: "mb-9" })`
  margin-top: 0px;
`;

const InfoCardHeader = styled.strong`
  color: ${spectrum.indigo50};
`;

const InfoCardPretitle = styled.p`
  &&& {
    font-size: 10px;
    line-height: 16px;
  }
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: ${spectrum.indigo30};
`;

/**
 * Creates an Introduction to Programmatic Writes with Meta Guide
 */
const ProgrammaticWritesIntro = ({
  location,
}: PageProps<unknown, unknown, Record<string, unknown> | null>) => (
  <StaticFancyPageContainerWithTOC
    tableOfContents={[
      {
        text: (
          <>
            Introducing <code>/meta</code>
          </>
        ),
        anchorSlug: "introducing-meta",
      },
      {
        text: (
          <>
            GET Request to <code>/meta</code>
          </>
        ),
        anchorSlug: "get-request-to-meta",
        linkItems: [{ text: "Account Token" }],
      },
      {
        text: (
          <>
            Response from <code>/meta</code>
          </>
        ),
        anchorSlug: "response-from-meta",
        linkItems: [
          {
            text: <code>request_schema</code>,
            anchorSlug: "request_schema",
            linkItems: [
              { text: "Common Model Fields" },
              {
                text: "Non-Common Model Fields",
                linkItems: [
                  {
                    text: "Integration-Specific",
                    anchorSlug: "integration-specific-fields",
                  },
                  { text: "Account-Specific", anchorSlug: "linked-account-specific-fields" },
                ],
              },
            ],
          },
          {
            text: <code>status</code>,
            anchorSlug: "status",
          },
          {
            text: "Helper Variables",
          },
        ],
      },
    ]}
  >
    <DocsHelmet
      title="Merge Writes: Introduction to Programmatic Writes"
      description="Learn how to use Merge Writes' Programmatic Writes with /meta."
    />
    <SmallWidthSection>
      <NavigationScrollTracker>
        <HeaderBar
          title="Programmatic Writes with /meta"
          subtitle={
            <>
              Use <code>/meta</code> to ensure you are including the correct <code>model</code>{" "}
              fields when making POST requests to Merge across varying linked accounts
            </>
          }
        />
      </NavigationScrollTracker>
    </SmallWidthSection>

    <ShortSection>
      <NavigationScrollTrackerWithAnchor
        headingLevel="h3"
        anchorSlug="introducing-meta"
        title={
          <>
            Introducing <code>/meta</code>
          </>
        }
      >
        <p>
          Merge’s Common Models have most fields you need, but there are advanced use cases where
          the fields available for POST requests to Merge change based on the third-party platform
          or user.
        </p>
        <p>
          Merge’s <code>/meta</code> endpoint helps you account for these use cases by dynamically
          fetching <code>model</code>
          field names, types, and other metadata used for making writes{" "}
          <strong>to a given integration or Linked Account.</strong>
        </p>
        <p>
          Instead of building customized logic for every integration, <code>/meta</code> allows you
          to:
        </p>
        <ul>
          <li>
            Dynamically prompt users for <strong>required</strong> / <strong>optional</strong> and{" "}
            <strong>standard</strong> / <strong>non-standard</strong> fields
          </li>
          <li>
            Build once to create or update data for <strong>all third-party platforms</strong>
          </li>
          <li>
            Programmatically account for{" "}
            <strong>Linked Account differences and customizations</strong>
          </li>
          <li>
            <strong>Validate</strong> POST requests for all required information
          </li>
        </ul>
        <aside className="mt-9">
          <code>/meta</code> can be useful for generating UIs and forms within your application
          where the prompts change based on the user.
        </aside>
        <CodeExample
          folder={CODE_EXAMPLE_FOLDER}
          codeBlockName={
            <p>
              Example <code>/meta</code> response
            </p>
          }
          fileBaseName="meta-example"
          colorScheme="light"
          hasLineNumbers={false}
          hideCopyButtonWithHeader
          style={{ marginTop: "48px" }}
        />
      </NavigationScrollTrackerWithAnchor>
    </ShortSection>
    <SectionDivider />

    <Section>
      <NavigationScrollTrackerWithAnchor
        headingLevel="h3"
        title={
          <>
            GET Request to <code>/meta</code>
          </>
        }
        anchorSlug="get-request-to-meta"
      >
        <p>
          All Merge POST endpoints have an associated <code>/meta</code> GET endpoint.
        </p>
        <p>
          For example, to get the integration- or account-specific schema for a successful POST
          request to <code>/candidates</code>, make a GET request to{" "}
          <code>/candidates/meta/post</code> first.
        </p>
      </NavigationScrollTrackerWithAnchor>
      <NavigationScrollTrackerWithAnchor headingLevel="h4" title="Account Token">
        <p>
          To identify the specific integration and Linked Account, pass the{" "}
          <code>account_token</code> for the Linked Account you are looking to write data to in the
          header of your API request.
        </p>
        <aside>
          Refer to our guide to <Link to={LINKS.AUTHENTICATION.linkTo}>Authentication</Link> to
          learn more about using <code>account_tokens</code>.
        </aside>
      </NavigationScrollTrackerWithAnchor>
    </Section>

    <SectionDivider />

    <Section>
      <NavigationScrollTrackerWithAnchor
        headingLevel="h3"
        title={
          <>
            Response from <code>/meta</code>
          </>
        }
        anchorSlug="response-from-meta"
      >
        <p>
          The response from <code>/meta</code> will return requirements for writing data to a
          specific integration or Linked Account.
        </p>
        <p>The response will contain three components:</p>
        <ul>
          <li>
            <code>request_schema</code>
          </li>
          <li>
            <code>status</code>
          </li>
          <li>
            booleans that make it easier to work with <code>request_schema</code> and build
            programmatic flows
          </li>
        </ul>
      </NavigationScrollTrackerWithAnchor>
      <NavigationScrollTrackerWithAnchor
        headingLevel="h4"
        title={<code>request_schema</code>}
        anchorSlug="request_schema"
      >
        <p>
          The <code>request_schema</code> object is the bulk of the <code>/meta</code> response and
          contains details about the <code>model</code> fields for the POST request specific to the
          integration or Linked Account.
        </p>
        <p>
          Specifically, <code>model</code> in the <code>/meta</code> response will have the
          following properties:
        </p>
        <div className="d-flex flex-column align-items-center mb-16 mt-9">
          <table>
            <thead>
              <tr>
                <td>
                  <code>model</code> Property
                </td>
                <td>Description</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Common Model fields</td>
                <td>
                  The Common Model fields listed here are writable to the integration or Linked
                  Account.
                </td>
              </tr>
              <tr>
                <td>
                  <code>integration_params</code>
                </td>
                <td>
                  Integration-specific writable <code>model</code> fields not part of Merge’s Common
                  Model.
                </td>
              </tr>
              <tr>
                <td>
                  <code>linked_account_params</code>
                </td>
                <td>
                  Linked Account-specific writable <code>model</code> fields not part of Merge’s
                  Common Model.
                </td>
              </tr>
              <tr>
                <td>
                  <code>remote_template_id</code>
                </td>
                <td>
                  <p>
                    In some cases, this enum input from your user determines whether there are
                    additional field inputs required from your user.
                  </p>
                  <p>
                    Learn more in our guide to{" "}
                    <Link to={LINKS.MERGE_WRITES_PROGRAMMATIC_TEMPLATES_CONDITIONAL.linkTo}>
                      Programmatic Writes with /meta for Fields Conditional on User Input.
                    </Link>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <aside>
          <p>
            Merge uses <strong>JSON Schema</strong> conventions in <code>request_schema</code> for
            scalable schemas and programmatic convenience.
          </p>
          <p>
            Learn more in our guide to{" "}
            <Link to={LINKS.SCHEMA_PROPERTIES.linkTo}>Schema Properties</Link>.
          </p>
        </aside>
      </NavigationScrollTrackerWithAnchor>
      <NavigationScrollTrackerWithAnchor
        headingLevel="h5"
        title="Common Model Fields"
        className="mt-16"
      >
        <p>
          Given the natural differences in third-party platforms, the <code>request_schema</code> is
          useful for determining <strong>writability</strong> and <strong>optionality</strong> of
          each Common Model field for each integration.
        </p>
        <p>
          See an example of a basic <code>request_schema</code> below:
        </p>
      </NavigationScrollTrackerWithAnchor>
    </Section>

    <RequestSchemaCodeExample
      folder={CODE_EXAMPLE_FOLDER}
      fileBaseName="request-schema"
      explanation={
        <p>
          See an example of a basic <code>request_schema</code>:
        </p>
      }
      codeBlockName={
        <>
          Example <code>request_schema</code>
        </>
      }
    />
    <RequestBodyCodeExample folder={CODE_EXAMPLE_FOLDER} fileBaseName="request-body" />

    <SmallWidthSection>
      <NavigationScrollTrackerWithAnchor
        headingLevel="h5"
        title="Non-Common Model Fields"
        className="mt-16"
      >
        <p>
          In addition to Common Model fields, some integrations and Linked Accounts may have varying
          writable <strong>Non-Common Model fields</strong> (fields not part of Merge’s standardized
          data model).
        </p>
        <p>
          These fields are contained in <code>integration_params</code> and{" "}
          <code>linked_account_params</code>.
        </p>
      </NavigationScrollTrackerWithAnchor>
      <NavigationScrollTrackerWithAnchor
        headingLevel="h6"
        title={
          <>
            Integration-Specific Fields (<code>integration_params</code>)
          </>
        }
        anchorSlug="integration-specific-fields"
        className="mt-9 pt-2"
      >
        <p>
          Non-Common Model fields that are{" "}
          <strong>specific to certain third-party platforms</strong> will be contained in{" "}
          <code>integration_params</code>.
        </p>
      </NavigationScrollTrackerWithAnchor>
    </SmallWidthSection>

    <RequestSchemaCodeExample
      folder={CODE_EXAMPLE_FOLDER}
      fileBaseName="request-schema-with-params"
      explanation={
        <>
          <p>
            Let’s say an ATS platform requires a <code>stage</code> field for newly created{" "}
            <code>Candidates</code>, even though this field is not included in our Common Model.
          </p>
          <p>
            As a result, the <code>Candidate’s stage</code> must be added to <code>model</code> in
            the body of POST requests to Merge for this specific third-party platform.
          </p>
          <p>
            The <code>request_schema</code> for this integration may look like:
          </p>
        </>
      }
      codeBlockName={
        <>
          Example <code>request_schema</code>
        </>
      }
    />
    <RequestBodyCodeExample folder={CODE_EXAMPLE_FOLDER} fileBaseName="request-body-with-params" />

    <Row className="pb-md-5 mb-md-6" style={{ pointerEvents: "none" }}>
      <Content md={6}>
        <InfoCard style={{ pointerEvents: "auto" }}>
          <p>
            <InfoCardHeader>
              A Note on Integration-Specific Fields and Linked Account-Specific Enum Values
            </InfoCardHeader>
            <p>
              If you are writing to an integration with an integration-specific Non-Common Model
              field with <strong>consistent enum choices</strong> across all Linked Accounts (like{" "}
              <code>Archived</code> and <code>Active</code> in the above example), then it may be
              unnecessary to use <code>/meta</code> to dynamically form POST requests since you can
              anticipate the choices for the field.
            </p>
            <p>
              However, if that integration-specific field has{" "}
              <strong>enum choices that vary by Linked Account</strong>, then it would be necessary
              to use <code>/meta</code> to dynamically surface the enum choices to your user.
            </p>
          </p>
          <InfoCardPretitle>Example:</InfoCardPretitle>
          <p>
            Instead of <code>stage</code> having fixed enum options of <code>Archived</code> and{" "}
            <code>Active</code>, let’s say that <strong>different Linked Accounts</strong> for the
            same integration have <strong>varying enum choices</strong> for the <code>stage</code>{" "}
            field (which is not part of Merge’s <code>Candidate</code> Common Model).
          </p>
          <p>
            For example, <strong>Linked Account A</strong> could have <code>Archived</code> and{" "}
            <code>Active</code> stages and <strong>Linked Account B</strong> could have{" "}
            <code>Unprocessed</code> and <code>Processed</code> stages (illustrated below).
          </p>
          <p>
            When passing the different Linked Account tokens in your GET request to{" "}
            <code>/meta</code>, the <code>integration_params</code> object in each{" "}
            <code>/meta</code> response would look like the following:
          </p>
          <Row>
            <Col md={6}>
              <p>
                <strong className="mb-6">Linked Account A</strong>
              </p>
              <CodeExample
                folder={CODE_EXAMPLE_FOLDER}
                fileBaseName="integration-params-a"
                colorScheme="light"
                hideOption
                hasLineNumbers={false}
                isVerticallyPadded={false}
                hideCopyButtonWithHeader
              />
            </Col>
            <Col md={6}>
              <p>
                <strong className="mb-6">Linked Account B</strong>
              </p>
              <CodeExample
                folder={CODE_EXAMPLE_FOLDER}
                fileBaseName="integration-params-b"
                colorScheme="light"
                hideOption
                hasLineNumbers={false}
                isVerticallyPadded={false}
                hideCopyButtonWithHeader
              />
            </Col>
          </Row>
        </InfoCard>
      </Content>
    </Row>

    <SmallWidthSection>
      <NavigationScrollTrackerWithAnchor
        headingLevel="h6"
        title={
          <>
            Linked Account-Specific Fields (<code>linked_account_params</code>)
          </>
        }
        className="mb-9 mt-9"
        anchorSlug="linked-account-specific-fields"
      >
        <p>
          Non-Common Model fields that are <strong>specific to Linked Accounts</strong> will be
          contained within the <code>linked_account_params</code> object within the{" "}
          <code>request_schema</code>.
        </p>
      </NavigationScrollTrackerWithAnchor>
    </SmallWidthSection>

    <RequestSchemaCodeExample
      folder={CODE_EXAMPLE_FOLDER}
      fileBaseName="request-schema-linked-account-params"
      explanation={
        <>
          <p>
            An ATS integration can allow a Linked Account to specify that a{" "}
            <code>hackerrank_score</code> is required when creating a <code>Candidate</code>.
          </p>
          <p>
            Since <code>hackerrank_score</code> isn’t a field in Merge’s <code>Candidate</code>{" "}
            Common Model and it’s writability depends on the Linked Account’s configuration, you
            would use <code>/meta</code> to determine whether this input is required from your user.
          </p>
          <p>
            Here is an example <code>/meta</code> response denoting the{" "}
            <code>hackerrank_score</code> field as required for the given Linked Account:
          </p>
        </>
      }
      codeBlockName={
        <>
          Example <code>request_schema</code> with <code>linked_account_params</code>
        </>
      }
    />
    <RequestBodyCodeExample
      folder={CODE_EXAMPLE_FOLDER}
      fileBaseName="request-body-linked-account-params"
    />

    <SmallWidthSection>
      <NavigationScrollTrackerWithAnchor
        title={
          <>
            <code>status</code>
          </>
        }
        headingLevel="h4"
        className="pt-md-3 mt-sm-3 mt-md-6 mb-9"
        anchorSlug="status"
      >
        <p>
          The <code>status</code> object within <code>/meta</code> can tell you if an integration is
          down or if a Linked Account is not properly connected, either of which will block POST
          requests.
        </p>
        <p>
          <code>status</code> has three properties:
        </p>

        <div className="d-flex flex-column align-items-center mb-9 mt-9">
          <table>
            <thead>
              <tr>
                <td>Property</td>
                <td>Type</td>
                <td>Options</td>
                <td>Description</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <code>integration_status</code>
                </td>
                <td>Enum</td>
                <td>
                  <code>OK</code>, <code>DOWN</code>
                </td>
                <td>Operational status of integration.</td>
              </tr>
              <tr>
                <td>
                  <code>linked_account_status</code>
                </td>
                <td>Enum</td>
                <td>
                  <code>COMPLETE</code>, <code>RELINK_NEEDED</code>
                </td>
                <td>Connection status of Linked Account.</td>
              </tr>
              <tr>
                <td>
                  <code>can_make_request</code>
                </td>
                <td>boolean</td>
                <td>
                  <code>true</code>, <code>false</code>
                </td>
                <td>
                  Returns <code>true</code> if <code>integration_status</code> is <code>OK</code>{" "}
                  and <code>linked_account_status</code> is <code>COMPLETE</code>. This property is
                  included for convenience.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <aside>
          If the Linked Account is not properly linked and shows a status of{" "}
          <code>RELINK_NEEDED</code>, you can find additional error details in your{" "}
          <a href="https://app.merge.dev/linked-accounts/accounts/">Linked Accounts dashboard</a>.
        </aside>
      </NavigationScrollTrackerWithAnchor>
    </SmallWidthSection>

    <SmallWidthSection>
      <NavigationScrollTrackerWithAnchor
        title="Helper Variables"
        headingLevel="h4"
        className="pt-6 mb-9 mt-9"
      >
        <p>
          The <code>/meta</code> response also includes a few helper variables for convenience:
        </p>

        <div className="d-flex flex-column align-items-center mb-9 mt-9">
          <table>
            <thead>
              <tr>
                <td>Property</td>
                <td>Type</td>
                <td>Options</td>
                <td>Description</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <code>has_required_linked_account_params</code>
                </td>
                <td>boolean</td>
                <td style={{ minWidth: "9em" }}>
                  <code>true</code>, <code>false</code>
                </td>
                <td>
                  Returns <code>true</code> if there are required Linked Account-specific fields
                  contained within the <code>/meta</code> response.
                </td>
              </tr>
              <tr>
                <td>
                  <code>has_conditional_fields</code>
                </td>
                <td>boolean</td>
                <td style={{ minWidth: "9em" }}>
                  <code>true</code>, <code>false</code>
                </td>
                <td>
                  Returns <code>true</code> if there are POST request fields conditional on your
                  user’s input in another field.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <aside>
          Learn more about what to do when <code>has_conditional_fields</code> is <code>true</code>{" "}
          in our guide to{" "}
          <Link to={LINKS.MERGE_WRITES_PROGRAMMATIC_TEMPLATES_CONDITIONAL.linkTo}>
            Templates and Conditional Fields.
          </Link>
        </aside>
      </NavigationScrollTrackerWithAnchor>
    </SmallWidthSection>

    <Section>
      <CrossGuideLinks location={location} usesQuaternaryLinks />
    </Section>
  </StaticFancyPageContainerWithTOC>
);

export default ProgrammaticWritesIntro;
